// config.js

const bizIcon = 'https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/s5cXg5gjnmI3FUedKLde/media/67479e076b2c48a0e36defee.png';
const myBizIcon = 'https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg';
const imageOne = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/s5cXg5gjnmI3FUedKLde/media/63e591b0560fb7792d9a220d.jpeg";
const imageTwo = "https://assets.cdn.filesafe.space/s5cXg5gjnmI3FUedKLde/media/c39db319-a86c-4d12-b5a4-c884ef0ecf76.jpeg";
const imageThree = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/s5cXg5gjnmI3FUedKLde/media/31a076f6-6405-4d5d-832f-2630ab05838a.png";
const imageFour = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/s5cXg5gjnmI3FUedKLde/media/64b6f803f601aa7646460906.jpeg";
const imageFive = "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/s5cXg5gjnmI3FUedKLde/media/64b6ec4ccb72ab9a1890aee2.jpeg";

const redirectLink = "https://jbautopainting.com/";

export const siteConfig = {
  title: "JB Auto Painting",
  metaDescription: "JB Auto Painting",
  theme: {
    primaryColor: "blue-600",
    secondaryColor: "gray-900",
    accentColor: "text-blue-600"
  },
  logo: {
    src: bizIcon,
    alt: "JB Auto Painting Icon",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "JB Auto Painting",
    headline: "Your Trusted Auto Painting Experts",
    address: "11656 Plank Rd",
    description: `
      JB Auto Painting is your go-to solution for high-quality automotive painting services. Located at 11656 Plank Rd, we specialize in restoring vehicles with precision and care, using cutting-edge techniques and top-tier materials. Whether you’re looking to refresh your car’s appearance, fix imperfections, or add a custom touch, JB Auto Painting delivers exceptional results with unparalleled customer service.
    `,
  },
  images: {
    business: {
      src: imageOne,
      alt: "JB Auto Painting Icon",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
      description: 
        "High-Quality Materials: We use premium automotive paints and materials to ensure durability, vibrant colors, and a flawless finish.",
    },
    {
      description: 
        "Expert Techniques: Our skilled technicians employ advanced painting methods to provide a factory-like finish that enhances your vehicle’s appearance.",
    },
    {
      description: 
        "Custom Solutions: From minor touch-ups to full-body repaints, we offer personalized services to meet your specific needs and preferences.",
    },
  ],  
  services: {
    sectionTitle: "Our Services",
    description: 
      "JB Auto Painting offers a range of professional automotive painting services, tailored to enhance your vehicle’s aesthetic and protect it from environmental elements. Whether it’s a full repaint, custom designs, or scratch repair, our expertise ensures your car stands out.",
    callouts: [
      {
        name: 'Full-Body Repainting',
        description: 
          "Transform your vehicle with a complete repaint. We’ll give your car a new, vibrant look, restoring it to showroom condition or achieving the custom color of your dreams.",
        imageSrc: imageTwo,
        imageAlt: 'Car receiving a full-body repaint at JB Auto Painting.',
      },
      {
        name: 'Scratch and Dent Repair',
        description: 
          "Our scratch and dent repair service restores your car’s surface, eliminating imperfections for a seamless finish that matches the original paint.",
        imageSrc: imageThree,
        imageAlt: 'Technician repairing scratches on a car at JB Auto Painting.',
      },
      {
        name: 'Custom Paint Jobs',
        description: 
          "Express your style with our custom paint jobs. We specialize in unique designs, intricate detailing, and multi-color finishes to make your vehicle truly one of a kind.",
        imageSrc: imageFour,
        imageAlt: 'Custom car paint design by JB Auto Painting.',
      },
    ],
  },  
  about: {
    sectionTitle: "About",
    description:   
      "At JB Auto Painting, we combine craftsmanship, innovation, and a passion for perfection to deliver outstanding automotive painting services. With years of experience and a dedicated team, we aim to transform vehicles and exceed customer expectations. Located on Plank Rd, we proudly serve our local community with professionalism and care.",
    image: {
      src: imageFive,
      alt: "The JB Auto Painting team delivering exceptional car painting services.",
      width: 1200,
      height: 800
    }
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "11656 Plank Rd, Eden, ON N0J 1H0, Canada",
    googleMapsEmbedUrl: 
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2863.793372015364!2d-80.7357310845146!3d42.72693717916186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882df9f9f12345:0xabcdef123456789!2s11656%20Plank%20Rd,%20Eden,%20ON%20N0J%201H0,%20Canada!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
    subCategories: [
      { 
        name: 'Apple Maps', 
        url: 'https://maps.apple.com/?address=11656%20Plank%20Rd,%20Eden,%20ON%20N0J%201H0&ll=42.726937,-80.735731&q=11656%20Plank%20Rd',
      },
      { 
        name: 'Bing Maps', 
        url: 'https://www.bing.com/maps?q=11656+Plank+Rd,+Eden,+ON+N0J+1H0,+Canada&FORM=HDRSC6',
      },
      { 
        name: 'Google Earth', 
        url: 'https://earth.google.com/web/search/11656+Plank+Rd,+Eden,+ON+N0J+1H0,+Canada/@42.726937,-80.735731,15a,814.57589625d,35y,0h,0t,0r',
      },
      { 
        name: 'Google Maps', 
        url: 'https://www.google.com/maps/place/11656+Plank+Rd,+Eden,+ON+N0J+1H0,+Canada/@42.726937,-80.735731,15z',
      },
    ],
  },  
  contact: {
    sectionTitle: "Get in Touch",
    description: "For quotes, appointments, or inquiries, contact JB Auto Painting. We’re here to help bring your car’s vision to life with our expert painting services.",
    socialLinks: {
      facebook: redirectLink,     
      instagram: redirectLink,     
      twitter: redirectLink,     
      linkedin: redirectLink,     
      youtube: redirectLink,     
    },
    contactNumbers: [
      "Mobile: (226) 231-4890",
    ],
    logo: bizIcon,
    emailRecipient: "jbautopainting@gmail.com",
  },
  footer: {
    bizSolutionsLogo: myBizIcon
  }
};


